import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import FullCTA from "../components/fullCTA"
import { BodyText } from "../components/bodyText"
import OverlayText from "../components/overlayText"

import "../assets/css/contact.scss"

const Contact = ({ data, location }) => {
  let [state, setState] = useState({
    uiState: "idle",
    formType: "",
  })
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url

  useEffect(() => {
    setState({
      ...state,
      formType: location.pathname.includes("mens-sign-up") ? "mens" : "contact",
    })
    // if (location.pathname === "/mens-sign-up") {
    //   var script = document.createElement("script")
    //   script.type = "text/javascript"
    //   script.src = "https://thestiltrust.activehosted.com/f/embed.php?id=1"

    //   document.head.appendChild(script)
    // }
  })

  const handleSubmit = async ev => {
    ev.preventDefault()
    const endpoint = ev.target.action
    let data = {}
    let fields = ev.target.querySelectorAll("input,textarea")
    fields.forEach(field => {
      data[field.name] = field.value
    })

    const response = await (
      await fetch(`${endpoint}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
    ).json()

    window.alert("Thank you, Your form has been submitted.")
    window.reload(true)

    return false
  }

  return (
    <Layout meta={article.pageMetaData || { title: article.title }}>
      <Hero data={article.hero} className="fixed" />
      <OverlayText>
        <BodyText article={article} align="left" />

        <section className="contact">
          {state.formType === "contact" && state.uiState !== "sent" && (
            <form
              action="/.netlify/functions/contact"
              onSubmit={ev => {
                handleSubmit(ev)
                setState({
                  ...state,
                  uiState: "sent",
                })
              }}
              name="contact"
              method="POST"
            >
              <div className="row">
                <label for="first-name">First Name</label>
                <input
                  id="first-name"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="row">
                <label for="last-name">Last Name</label>
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
              <div className="row">
                <label for="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
              </div>
              <div className="row">
                <label for="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                ></textarea>
              </div>

              <div className="row">
                <button type="submit">Submit</button>
              </div>
            </form>
          )}

          {state.formType === "mens" && state.uiState !== "sent" && (
            <form
              action="/.netlify/functions/signup"
              onSubmit={ev => {
                handleSubmit(ev)
                setState({
                  ...state,
                  uiState: "sent",
                })
              }}
              name="contact"
              method="POST"
            >
              <div className="row">
                <label for="first-name">Full Name</label>
                <input
                  id="first-name"
                  name="firstName"
                  type="text"
                  placeholder="Full Name"
                />
              </div>
              <div className="row">
                <label for="last-name">Last Name</label>
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
              <div className="row">
                <label for="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
              </div>

              <div className="row">
                <button type="submit">Submit</button>
              </div>
            </form>
          )}
        </section>

        {state.uiState === "sent" && (
          <div className="body">
            <div className="body-text">
              <p>
                <b>Thank you, your form has been submitted</b>
              </p>
            </div>
          </div>
        )}

        {article.ctas && article.ctas.map(cta => <FullCTA data={cta} />)}
      </OverlayText>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        subTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
    }
  }
`
